<template>
  <div class="content-block">
    <section class="block-header">
      <Masthead />
    </section>
    <section class="block-body">
      <Links />
    </section>
    <section class="block-footer">
      <MessageForm />
    </section>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Masthead from '@/components/Masthead.vue'
import Links from '@/components/Links.vue'
import MessageForm from '@/components/forms/MessageForm.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Masthead,
    Links,
    Footer,
    MessageForm,
  },
}
</script>

<style lang="scss">
</style>