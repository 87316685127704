<template>
  <header>
    <div class="avatar">
      <span>Hi.</span>
    </div>
    <h1>Pete McBride</h1>
    <p>Visual designer living in the Canadian prairies.</p>
  </header>
</template>

<style lang="scss">
@import '@/assets/sass/partials/_global.scss';

header {
  background-color: #fff;
  text-align: center;
  padding: 32px 24px 24px;
  p {
    color: $comp-lite;
  }
}
div.avatar {
  height: 112px;
  width: 112px;
  margin: 0 auto 20px;
  border-radius: 50%;
  background-image: url('../assets/img/avatar-pete.png');
  background-size: cover;
  background-color: $comp-lite;
  position: relative;
  span {
    position: absolute;
    top: -20px;
    right: -56px;
    color: $txt-dark;
    font-family: 'black';
    font-size: 2.8rem;
    text-align: center;
    padding-top: 14px;
    background-image: url('../assets/img/bubble.svg');
    display: block;
    width: 62px;
    height: 65px;
    background-repeat: none;
  }
}
</style>