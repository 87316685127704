<template>
  <form @submit.prevent="handleSubmit" class="form-colour_prime">
    <h2>Login</h2>
    <input type="email" required placeholder="Email" v-model="email">
    <input type="password" required placeholder="Password" v-model="password">
        <div v-if="error" class="error"><span><i class="fas fa-exclamation-triangle"></i></span> {{ error }}</div>
        <button v-if="!isPending">Log in</button>
        <button v-else disabled>Logging in…</button>
  </form>
</template>

<script>
import { ref } from 'vue'
import useLogin from '@/composables/useLogin'

export default {
  setup(props, context) {
    //refs
    const email = ref('')
    const password = ref('')
    const isPending = ref(false)

    const { error, login } = useLogin()

    const handleSubmit = async () => {
      await login(email.value, password.value)
      if (!error.value) {
        context.emit('login')
      }
    }

    return { email, password, handleSubmit, error, isPending }
  },
}
</script>

<style>
</style>