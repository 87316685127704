<template>
<div class="page-wrapper">
  <div class="content-block">
    <section class="block-header">
      <router-link :to="{name: 'Home'}" class="top-left"><span><i class="fas fa-home"></i></span></router-link>
      <Masthead />
    </section>
    <section class="block-footer">
      <div v-if="showLogin" class="login-block">
        <LoginForm @login="enterChat" />
      </div>
    </section>
    <Footer />
  </div>
</div>
</template>

<script>
import Masthead from '@/components/Masthead.vue'
import SignupForm from '@/components/forms/SignupForm'
import LoginForm from '@/components/forms/LoginForm'
import Footer from '@/components/Footer.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  components: { SignupForm, LoginForm, Footer, Masthead },
  setup() {
    const showLogin = ref(true)
    const router = useRouter()
    const enterChat = () => {
      router.push({ name: 'Dashboard' })
    }
    return { showLogin, enterChat }
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/partials/_global.scss';
div.page-wrapper {
  position: absolute;
  background-color: orangered;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
}
.login-block {
  min-height: 316px;
  background: $black;
  padding: 36px 24px;
}
@media screen and (min-width: 680px) {
  .login-block {
    padding: 36px 20% 48px;
  }
}
</style>