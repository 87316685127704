<template>
  <footer>
    <p>&copy;{{ todayYear }} Pete McBride. All rights reserved.</p>
    <p><img src="../assets/img/footer-logo.svg" alt="" /></p>
  </footer>
</template>

<script>
import getCurrentDate from '@/composables/getCurrentDate'

export default {
  setup() {
    const { todayYear } = getCurrentDate()
    return { todayYear }
  },
}
</script>



<style lang="scss">
@import '@/assets/sass/partials/_global.scss';
footer {
  p {
    margin: 40px 0;
    text-align: center;
    color: rgba($txt-dark, $alpha: 0.3);
    img {
      opacity: 0.3;
    }
  }
}
</style>
