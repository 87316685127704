<template>
  <Navbar v-if="user" />
  <div>
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import getUser from '@/composables/getUser'

export default {
  components: { Navbar },
  setup() {
    const { user } = getUser()
    return { user }
  },
}
</script>


<style lang="scss">
@import '@/assets/sass/app.scss';
</style>
