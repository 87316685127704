<template>
  <section class="link-block">
    <h2>Pete online</h2>
    <div class="card-stack link-cards">
      <a href="https://www.instagram.com/petemcbride/" class="instagram"
        >
        <span class="link-icon"><i class="fab fa-instagram"></i></span>
        <h3>Instagram</h3>
        <span class="bottom-span"></span
      ></a>
      <a href="https://twitter.com/petemcbride" class="twitter"
        >
        <span class="link-icon"><i class="fab fa-twitter"></i></span>
        <h3>Twitter</h3>
        <span class="bottom-span"></span>
      </a>
      <a href="https://dribbble.com/petemcbride" class="dribbble"
        >
        <span class="link-icon"><i class="fab fa-dribbble"></i></span>
        <h3>Dribbble</h3>
        <span class="bottom-span"></span>
      </a>
      <a href="https://www.behance.net/petemcbride" class="behance"
        >
        <span class="link-icon"><i class="fab fa-behance"></i></span>
        <h3>Behance</h3>
        <span class="bottom-span"></span>
      </a>
    </div>
  </section>
</template>

<style lang="scss">
@import '@/assets/sass/partials/_global.scss';

.link-block {
  background-color: $prime;
  padding: 24px 4%;
}
.card-stack {
  display: flex;
  justify-content: space-between;
}

div.link-cards > a {
  width: 24%;
  border-radius: $brad-dark;
  margin: 12px 0 0;
  border: 2px solid rgba($prime, 1);
  display: block;
  text-align: center;
  color: $light;
  background-color: $black;

  span.link-icon {
    display: inline-block;
    font-size: 4.2rem;
    margin: 12px 0 2px;
  }

  h3 {
    font-family: 'regular';
    color: #fff;
    opacity: 0.8;
    font-size: 1.2rem;
  }

  .bottom-span {
    display: inline-block;
    height: 3px;
    border-radius: 2px;
    margin-bottom: 2px;
    width: 32px;
    background: rgba($comp-lite, 0.4);
  }

  &:hover {
    border-color: rgba($second, 1);

    .bottom-span {
      background: rgba($comp-lite, 1);
    }
  }

  &:active {
    border-color: rgba($comp-lite, 1);
  }
}

@media screen and (min-width: 680px) {
  .link-block {
    padding: 24px 20%;
  }
}
</style>