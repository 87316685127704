import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'


const firebaseConfig = {
  apiKey: "AIzaSyBIkHE6_D5ZkQh_GkG3cWi9ByiDRAPL9LU",
  authDomain: "petemcbride-ca.firebaseapp.com",
  databaseURL: "https://petemcbride-ca.firebaseio.com",
  projectId: "petemcbride-ca",
  storageBucket: "petemcbride-ca.appspot.com",
  messagingSenderId: "673855124782",
  appId: "1:673855124782:web:7bad4d68c938849f88f6bf"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectFirestore, projectAuth, projectStorage, timestamp }
