<template>
  <section class="message-block">
    <div v-if="showForm">
      <form @submit.prevent="handleSubmit" class="form-colour_comp">
        <h2>Message Pete</h2>
        <input type="text" placeholder="Name… (required)" required v-model="name" />
        <input type="email" placeholder="Email… (if you want a response)" v-model="email" />
        <textarea name="" placeholder="Type your message here…" required v-model="message"></textarea>
        <div v-if="error" class="error"><span><i class="fas fa-exclamation-triangle"></i></span> {{ error }}</div>
        <button v-if="!isPending"><span><i class="fas fa-paper-plane"></i></span>Send message</button>
        <button v-else disabled><span><i class="fas fa-paper-plane"></i></span>Sending…</button>
      </form>
    </div>
    <div v-else class="success">
      <div class="success-icon">
        <span><i class="fas fa-paper-plane"></i></span>
      </div>
      <div class="success-message">
        <h2>Your message has been sent, {{ name }}!</h2>
        <p v-if="email">If you're wanting a reply, please allow me a few days to get back to you.</p>
        <p>Thanks for getting in touch.</p>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import useCollection from '@/composables/useCollection'
import { timestamp } from '@/firebase/config'

export default {
  setup() {
    const { addDoc, error } = useCollection('messages')

    const name = ref('')
    const email = ref('')
    const message = ref('')
    const showForm = ref(true)
    const isPending = ref(false)

    const handleSubmit = async () => {
      isPending.value = true
      // create message data object to be added to collection
      const messageData = {
        name: name.value,
        email: email.value,
        message: message.value,
        archived: false,
        createdAt: timestamp(),
      }

      await addDoc(messageData)

      isPending.value = false

      if (!error.value) {
        message.value = ''
        showForm.value = false
      }
    }

    return { name, email, message, showForm, isPending, handleSubmit, error }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/_global.scss';
.message-block {
  min-height: 396px;
  background: $black;
  padding: 24px;
}
@media screen and (min-width: 680px) {
  .message-block {
    padding: 24px 20% 48px;
  }
}
</style>