import { ref } from "vue"
import { projectFirestore } from '../firebase/config'

const useCollection = (collection) => {
  const error = ref(null)

  let collectionRef = projectFirestore.collection(collection)

  const addDoc = async (doc) => {
    error.value = null
    try {
      await collectionRef.add(doc)
    } catch (err) {

      console.log(err.message)
      error.value = 'Could not complete action'

    }
  }

  const deleteDoc = async (id) => {
    error.value = null
    try {
      await collectionRef.doc(id).delete()
    } catch (err) {
      error.value = 'Could not delete'
    }
  }

  const updateDoc = async (id, updates) => {
    error.value = null
    try {
      await collectionRef.doc(id).update(updates)
    } catch (err) {
      error.value = 'Could not update'
    }
  }

  return { error, addDoc, deleteDoc, updateDoc }
}

export default useCollection