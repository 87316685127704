import { ref } from 'vue'

// set ref to be exported
const todayDate = ref('')
const todayMonth = ref('')
const todayMonthShort = ref('')
const todayDay = ref('')
const todayYear = ref('')
const todayHour = ref('')
const todayMinute = ref('')
const todayPeriod = ref('')

// used to convert month into string
const month = new Array()
month[0] = "January"
month[1] = "February"
month[2] = "March"
month[3] = "April"
month[4] = "May"
month[5] = "June"
month[6] = "July"
month[7] = "August"
month[8] = "September"
month[9] = "October"
month[10] = "November"
month[11] = "December"

// used to create day into string
const day = new Array()
day[0] = "Sunday"
day[1] = "Monday"
day[2] = "Tuesday"
day[3] = "Wednesday"
day[4] = "Thursday"
day[5] = "Friday"
day[6] = "Saturday"

const getCurrentDate = () => {

  // set current date data using the Date() object
  let dateNow = new Date()
  let currentMonth = month[dateNow.getMonth()]
  let currentDate = dateNow.getDate()
  let currentDay = day[dateNow.getDay()]

  // set exported refs to current date data
  todayDate.value = currentDate
  todayDay.value = currentDay
  todayYear.value = dateNow.getFullYear()
  todayMonth.value = currentMonth
  todayMonthShort.value = currentMonth.substring(0, 3)

  const updateClock = () => {
    let currentTime = new Date()
    let hour = currentTime.getHours()
    let minute = currentTime.getMinutes()
    let period = hour >= 12 ? "PM" : "AM"
    hour = hour % 12
    hour = hour ? hour : 12 // the hour '0' should be '12'
    minute = minute < 10 ? "0" + minute : minute

    // update html with current time
    todayHour.value = hour
    todayMinute.value = minute
    todayPeriod.value = period
  }



  return { updateClock, todayDate, todayDay, todayYear, todayHour, todayMinute, todayPeriod, todayMonth, todayMonthShort }

}

export default getCurrentDate